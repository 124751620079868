import React from "react";
import PodDesignPNG from "../../img/XEO_POD_P2_FaRe.png"

export const PodDesignPage = () => {
    return (
        <div className="w-full h-[836px]">
            <div className="w-full h-[836px] top-0 left-0">
                <div className="relative w-full h-[836px] bg-[#fbfbfb]">
                    <img
                        className="absolute h-[793px]"
                        alt="Xeo POD fare"
                        src={PodDesignPNG}
                    />
                    <div className="absolute w-[99px] top-[19px] left-[50px] font-subject font-[number:var(--subject-font-weight)] text-[#b0b0b0] text-[length:var(--subject-font-size)] tracking-[var(--subject-letter-spacing)] leading-[var(--subject-line-height)] [font-style:var(--subject-font-style)]">
                        Design
                    </div>
                    <p className="absolute w-[421px] top-[198px] left-[162px] font-h2-sub font-[number:var(--h2-sub-font-weight)] text-black text-3xl tracking-[var(--h2-sub-letter-spacing)] leading-[var(--h2-sub-line-height)] [font-style:var(--h2-sub-font-style)]">
                        Hard on the outside. <br />
                        Soft and friendly on the inside
                    </p>
                    <p className="absolute w-[384px] top-[354px] left-[163px] font-body-1 font-[number:var(--body-1-font-weight)] text-black text-[length:var(--body-1-font-size)] tracking-[var(--body-1-letter-spacing)] leading-[var(--body-1-line-height)] [font-style:var(--body-1-font-style)]">
                        XEO’s pentagonal, architectural silhouette is unashamedly eye-catching. Odd, even. Its sharp lines make a
                        statement. But, when you sit inside, it feels like being in an upholstered egg: beautifully comfortable,
                        whilst also being supportive. <br />
                        <br />
                        This attention to detail is especially important because you will naturally want to be in your <br />
                        XEO Pod for long periods of time.
                    </p>
                </div>
            </div>
        </div>
    );
};
import '../../App.css';
import {Navbar} from "../../Navbar/Navbar";
import {SignUpNewsletter} from "../Landing/SignUpNewsletter/SignUpNewsletter";
import {Footer} from "../../Footer/Footer";
import {PodChairVideo} from "./PodChairVideo";
import {TheExperience} from "./TheExperience";
import {StepIntoTheGame} from "./StepIntoTheGame";
import {TheSound} from "./TheSound";
import {PodDesignPage} from "./PodDesign";
import {LineDrawing} from "./LineDrawing";
import {CameraBlock} from "./Camera";
import {LightsBlock} from "./Lights";
import {SpecsBlock} from "./Specs";
import {ColoeBlock} from "./Coloe";


function PodChair() {
    return (
        // PodChair
        <div className="flex flex-col w-full bg-white">
            <div className="flex flex-col w-full"><Navbar/></div>
            <div className="flex flex-col w-full"><PodChairVideo/></div>
            <div className="flex flex-col w-full"><TheExperience/></div>
            <div className="flex flex-col w-full"><StepIntoTheGame/></div>
            <div className="flex flex-col w-full"><TheSound/></div>
            <div className="flex flex-col w-full"><PodDesignPage/></div>
            <div className="flex flex-col w-full"><LineDrawing/></div>
            <div className="flex flex-col w-full"><CameraBlock/></div>
            <div className="flex flex-col w-full"><LightsBlock/></div>
            <div className="flex flex-col w-full"><SpecsBlock/></div>
            <div className="flex flex-col w-full"><SignUpNewsletter/></div>
            <div className="flex flex-col w-full"><ColoeBlock/></div>
            <div className="flex flex-col w-full"><Footer/></div>
        </div>
    );
}


export default PodChair;



import './App.css';
import {Navbar} from "./Navbar/Navbar";
import PodChairTeaser from "./Desktop/Landing/PodChairTeaser/PodChairTeaser";
import {XEOCompanyTeaser} from "./Desktop/Landing/XEOCompany/XEOCompany";
import {QuiltyPleasure} from "./Desktop/Landing/QuiltyPleasure/QuiltyPleasure";
import {SignUpNewsletter} from "./Desktop/Landing/SignUpNewsletter/SignUpNewsletter";
import {Footer} from "./Footer/Footer";


function Landing() {
    return (
        // Landing
        <div className="flex flex-col w-full bg-white">
            <div className="flex flex-col w-full"><Navbar/></div>
            <div className="flex flex-col w-full"><PodChairTeaser/></div>
            <div className="flex flex-col w-full"><XEOCompanyTeaser/></div>
            <div className="flex flex-col w-full"><QuiltyPleasure/></div>
            <div className="flex flex-col w-full"><SignUpNewsletter/></div>
            <div className="flex flex-col w-full"><Footer/></div>
        </div>
    )

}

export default Landing;
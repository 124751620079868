import React from "react";
import ArrowDown from "../../img/arrow-down.png"
import Overwatch from "../../img/Overwatch.png"

export const StepIntoTheGame = () => {
    return (
        <div className="w-full ">
            <div className="flex flex-col w-full h-[234px] bg-white">
                <div className="m-auto">
                <p className="[font-family:'Untitled_Sans-Medium',Helvetica] font-medium text-black text-[40px] tracking-[-0.80px] leading-[normal]">
                    From playing the game to living the game...
                </p>
                <img className="m-auto mt-5 w-[54px] h-[27px]" alt="Vector" src={ArrowDown}/>
                </div>
            </div>
            <div>
                <img alt="OverWatch2" src={Overwatch}/>
            </div>
        </div>
    );
};
import React from "react";
import xeoLogo from "../../../img/logo-black.png"

export const XEOCompanyTeaser = () => {
    return (
        <div className="flex-col relative justify-start ml-40 mt-40">
            <div className="mb-40">
                <img className="relative" alt="Xeo logo" src={xeoLogo}/>
                <br/>
                <p className="text-black ml-4">
                <span className="leading-[21.6px]">
                  We are all about High-end audio, <br/>
                  immersive experiences and design. <br/>
                  <br/>
                  XEO is a new audio brand conceived <br/>
                  inside Dynaudio, the pioneering <br/>
                  Danish loudspeaker company
                  <br/>
                </span>
                    <span className="leading-[24px]">
                  <br/>
                </span>
                    <span className="text-[#7f00ff] leading-[24px] underline">Wanna know more?</span>
                </p>
            </div>
        </div>
    );
};
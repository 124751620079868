import React from "react";
import CMFVideoCapture from "../../img/CMFVideo.png"
import BlackPod from "../../img/20240403-101756.png"
import WhitePod from "../../img/20240403-101805.png"

export const ColoeBlock = () => {
    return (
        <div className="w-full">
            <div className="bg-neutral-100">
                <div className="relative w-full top-0 left-[163px]">
                    {/*<img alt="CMF Video Capture" src={CMFVideoCapture}/>*/}
                    {/*<div*/}
                    {/*    className="absolute w-[122px] top-[528px] left-[355px] font-helvetica_neue_light font-light text-black text-[18px] tracking-[-0.36px] leading-[normal]">*/}
                    {/*    The dark side*/}
                    {/*</div>*/}
                    {/*<div*/}
                    {/*    className="w-[122px] top-[525px] left-[1048px] font-helvetica_neue_light font-light text-black text-[18px] tracking-[-0.36px] leading-[normal]">*/}
                    {/*    Starship white*/}
                    {/*</div>*/}

                    <p className="absolute w-[385px] top-[103px] left-0 font-h2-sub font-[number:var(--h2-sub-font-weight)] text-black text-4xl tracking-[var(--h2-sub-letter-spacing)] leading-[var(--h2-sub-line-height)] [font-style:var(--h2-sub-font-style)]">
                        You can have it in any color you like, as long as it is black... or white
                    </p>
                    <div className="diff aspect-[16/9]">
                        <div className="diff-item-1">
                            <img alt="daisy" src={BlackPod}/>
                        </div>
                        <div className="diff-item-2">
                            <img alt="daisy" src={WhitePod} />
                        </div>
                        <div className="diff-resizer"></div>
                    </div>
                </div>

            </div>
        </div>
    );
};
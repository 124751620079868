import React, {useState} from "react";
import xeoLogoPNG from "../img/logo-black.png"

const menuButtonSVG = (
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="#7f00ff"
        xmlns="http://www.w3.org/2000/svg"
        className="hover:fill-button-hover-color"
    >
        {/* 实现加粗的横线按钮 */}
        <rect width="31" height="6"/>
        <rect y="12" width="31" height="6"/>
        <rect y="24" width="31" height="6"/>
    </svg>
);

const XEOLogo = () => {
    return (
        <div className="w-[136px] h-[54px]">
            <img
                className="w-[136px] h-[54px] top-0 left-0"
                alt="Xeo logo black"
                src={xeoLogoPNG}
            />
        </div>
    );
};

export const Navbar = () => {
    // 使用useState钩子来追踪菜单是否展开的状态
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // 定义一个函数来切换菜单的展开状态
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="flex">
            <div className="grid grid-cols-2 w-full h-[106px] bg-white flex-col">
                <div className="relative top-[26px] left-[42px]"><XEOLogo/></div>
                <button className="relative justify-self-end right-[52px]" onClick={toggleMenu}>{menuButtonSVG}</button>
                {/* 根据isMenuOpen状态来决定是否展示菜单 */}
                {isMenuOpen && (
                    <div className="absolute flex-col top-[106px] right-0 text-black z-50 w-[1164px] text-right">
                        {/* 实际菜单内容可以在这里定义 */}
                        <ul className="absolute text-4xl font-bold bg-white w-[1164px]">
                            <div className="relative z-50 h-full w-full right-[32px]">
                                <div className="h-[114px] p-5 hover:text-button-hover-color items-center">
                                    <li><a href="/">Press</a></li>
                                </div>
                                <div className="h-[114px] p-5 hover:text-button-hover-color items-center">
                                    <li><a href="/pod">Pod Chair</a></li>
                                </div>
                                <div className="h-[114px] p-5 hover:text-button-hover-color items-center">
                                    <li><a href="/about">About</a></li>
                                </div>
                            </div>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

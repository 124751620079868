import React from "react";
import logoWhite from "../../img/logo-white.png"

export const AboutPod = () => {
    return (
        <div className="flex relative w-full h-[786px]">
            <div className="w-full h-[786px] top-0 left-0">
                <div className="relative w-ful h-[786px]">
                    <div className="absolute w-full h-[786px] top-0 left-0 bg-[#872deb] rotate-180" />
                    <p className="absolute w-[437px] top-[372px] left-[276px] font-body-1 font-[number:var(--body-1-font-weight)] text-white text-[length:var(--body-1-font-size)] tracking-[var(--body-1-letter-spacing)] leading-[var(--body-1-line-height)] [font-style:var(--body-1-font-style)]">
                        Pod is the first product from the design team at XEO.&nbsp;&nbsp; and has developed into a small, innovative
                        ideas lab. What began as an obsession with the quality of sound has developed into a passion for creating
                        environments that affect all the senses and make virtual environments feel real.
                    </p>
                    <p className="absolute w-[437px] top-[372px] left-[728px] font-body-1 font-[number:var(--body-1-font-weight)] text-white text-[length:var(--body-1-font-size)] tracking-[var(--body-1-letter-spacing)] leading-[var(--body-1-line-height)] [font-style:var(--body-1-font-style)]">
                        Some may say we are dreamers and maybe that’s true. But it’s only when you tap into human imagination that
                        you get products like the XEO Pod. Products that nobody ever asked for but suddenly need to have. Now that
                        the XEO Pod is here, you have to ask yourself, why didn’t anyone think of this before?
                    </p>
                    <p className="absolute w-[889px] top-[190px] left-[276px] font-h1 font-[number:var(--h1-font-weight)] text-white text-5xl tracking-[var(--h1-letter-spacing)] leading-[var(--h1-line-height)] [font-style:var(--h1-font-style)]">
                        Redefining what immersive sounds like
                    </p>
                    <img className="absolute w-[96px] h-[29px] top-[624px] left-[276px]" alt="Xeo" src={logoWhite} />
                </div>
            </div>
        </div>
    );
};
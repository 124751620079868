import React from "react";
import {MobileXEOTheCompant} from "./XEOTheCompant";
import {MobileSignUpNewsletter} from "../Landing/SignUpNewsletter";
import {Navbar} from "../../Navbar/Navbar";
import {MobileXEODevelopment} from "./XEODevelopment";
import {Footer} from "../../Footer/Footer";
import {MobileFooter} from "../Footer";


function MobileAbout() {
    return (
        // Mobile About Page
        <div className="flex flex-col w-full">
            <div><Navbar/></div>
            <div><MobileXEOTheCompant/></div>
            {/*<div><MobileXEODevelopment/></div>*/}
            <div><MobileSignUpNewsletter/></div>
            <div><MobileFooter/></div>
        </div>
    )

}

export default MobileAbout;

import React from "react";
import {MobileLandingExperience} from "./LandingExperience";
import {MobileLandingTheExperience} from "./TheExperience";
import {MobileSignUpNewsletter} from "../Landing/SignUpNewsletter";
import {MobileStepIntoTheGame} from "./StepIntoTheGame";
import {MobileOverwatch} from "./Overwatch";
import {MobileLightInside} from "./LightInside";
import {MobileTheSound} from "./TheSound";
import {MobileCamera} from "./CameraPage";
import {MobileSoftInside} from "./SoftInside";
import {MobilePosition} from "./Position";
import {MobileSpecs} from "./Specs";
import {MobileAccessories} from "./Accessories";
import {Navbar} from "../../Navbar/Navbar";
import {MobileFooter} from "../Footer";


function MobilePodchair() {
    return (
        // Mobile Landing Page
        <div className="flex flex-col w-full">
            <div><Navbar/></div>
            <div><MobileLandingExperience/></div>
            <div><MobileLandingTheExperience/></div>
            {/*<div><MobileStepIntoTheGame/></div>*/}
            {/*<div><MobileOverwatch/></div>*/}
            <div><MobileLightInside/></div>
            <div><MobileTheSound/></div>
            <div><MobileCamera/></div>
            <div><MobileSoftInside/></div>
            <div><MobilePosition/></div>
            <div><MobileSpecs/></div>
            <div><MobileSignUpNewsletter/></div>
            <div><MobileAccessories/></div>
            <div><MobileFooter/></div>
        </div>
    )
}

export default MobilePodchair;

import React from "react";
import yulla from "../../../img/yulla.png"

export const QuiltyPleasure = () => {
    return (
        <div className="">
            <div className="relative w-full m-auto">
                <img
                    className=""
                    alt="Yulllllllla an image"
                    src={yulla}
                />
                <div className="absolute top-0 w-full pt-60 pl-20 pr-20">
                    <p className="font-helvetica_neue_heavy font-bold text-white text-[60px] tracking-[-1.20px] leading-[60px]">
                        What’s your guilty <br/>
                        entertainment pleasure?
                    </p>
                    <div
                        className="mt-20 [font-family:'Untitled_Sans-NotLicensed_for_Desktop_Use',Helvetica] font-normal text-[#d9b4ff] text-[18px] text-right tracking-[-0.36px] leading-[18px] underline whitespace-nowrap">
                        <span>Endurance gaming</span>
                        <br/>
                        <span>Bollywood actionadventures</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
import React from "react";

//Images
import DolbyIcon from "../../img/Mobile/Podchair/dolby.png";
import SoundByDynaudioIcon from "../../img/Mobile/Podchair/Sound by Dynaudio-04-04.png";
import DstIcon from "../../img/Mobile/Podchair/dst.png"
import Auro3dIcon from "../../img/Mobile/Podchair/auro3d.png"


export const MobileLandingTheExperience = () => {
    return (
        <div className="flex flex-col bg-[#25CEA2] relative justify-center items-center pt-20 pb-5 pl-5 pr-5">
            <p className="flex flex-col relative text-white mb-10">
                <span className="font-helvetica_neue_heavy text-4xl font-bold">You are no longer just an observer</span>
                <br/>
                <span className="font-helvetica_neue_light mt-5">
                    XEO takes you further. You are no longer just watching and listening. You are sitting within a purpose-designed pod that insulates you from reality. It’s a multi-sensory experience unlike anything you thought possible.
                </span>
            </p>
            <div className="flex flex-row relative justify-center items-center h-[20px] w-full">
                <div className="basis-1/2 h-[20px]">
                    <img className="h-[20px] m-auto" src={DolbyIcon} alt="Dolby"/>
                </div>
                <div className="basis-1/2 h-[20px]">
                    <img className="h-[20px] m-auto" src={SoundByDynaudioIcon} alt="DST"/>
                </div>
                {/*<div className="basis-1/3 h-[20px]">*/}
                {/*    <img className="h-[20px] m-auto" src={DstIcon} alt="DST"/>*/}
                {/*</div>*/}
                {/*<div className="basis-1/3 h-[20px]">*/}
                {/*    <img className="h-[20px] m-auto" src={Auro3dIcon} alt="Auro3D"/>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}
import React from "react";

import NewFrontPNG from "../../img/NewFront.png"
import NewSlidePNG from "../../img/NewSide.png"
import SupportedICON from "../../img/Specs/SupportedDevices.png"
import ConnectivityICON from "../../img/Specs/Connectivity.png"
import ModesICON from "../../img/Specs/Modes.png"
import AudioICON from "../../img/Specs/Audio.png"
import FunctionsICON from "../../img/Specs/Functions.png"
import MaterialsICON from "../../img/Specs/Meterials.png"
import AccessoriesICON from "../../img/Specs/Accessories.png"


export const MobileSpecs = () => {
    return (
        <div className="flex flex-col">
            <p className="text-[#7f00ff] ml-5 mr-5 mt-20">
                <span className="flex text-4xl font-bold mb-5 font-helvetica_neue_heavy">
                    Nerdy? It’s time for the fact fest
                </span>
                <br/>
                <span className="font-helvetica_neue_light">
The specifications of the remarkable XEO Pod will impress, the more you examine them.
                </span>
            </p>
            <div className="flex relative pb-10 font-helvetica_neue_light">
                <img className="p-20 pb-10" src={NewFrontPNG} alt="Pod Front"/>
                <span className="absolute flex h-full justify-center items-center right-5">144cm</span>
                <span className="absolute flex w-full justify-center items-center bottom-10">120cm</span>
            </div>
            <div className="flex relative pb-10 font-helvetica_neue_light">
                <img className="p-20 pb-10 pt-0" src={NewSlidePNG} alt="Pod Slide"/>
                <span className="absolute flex w-full justify-center items-center bottom-10">102cm</span>
            </div>

            {/* 列表 */}
            <div className="flex flex-col relative p-5">
                <div className="flex flex-row relative pb-8">
                    <img className="h-[30px] w-[30px] m-3" src={SupportedICON} alt="Supported ICON"/>
                    <p>
                        <span className="text-2xl font-bold font-helvetica_neue_heavy">
                            Supported devices
                        </span>
                        <br/>
                        <span className="font-helvetica_neue_light">
                            PC, PS5, XBOX, Apple TV, VR devices with USB4 output
                        </span>
                    </p>
                </div>


                <div className="flex flex-row relative pb-8">
                    <img className="h-[30px] w-[30px] m-3" src={ConnectivityICON} alt="Connectivity ICON"/>
                    <p>
                        <span className="text-2xl font-bold font-helvetica_neue_heavy">
                            Connectivity
                        </span>
                        <br/>
                        <span className="font-helvetica_neue_light">
                            HDMI, Bluetooth, Apple Airplay2
                        </span>
                    </p>
                </div>


                <div className="flex flex-row relative pb-8">
                    <img className="h-[30px] w-[30px] m-3" src={ModesICON} alt="Modes ICON"/>
                    <p>
                        <span className="text-2xl font-bold font-helvetica_neue_heavy">
                            Modes
                        </span>
                        <br/>
                        <span className="font-helvetica_neue_light">
                            Gaming, Movie, Music, Relax
                        </span>
                    </p>
                </div>

                <div className="flex flex-row relative pb-8">
                    <img className="flex-0 h-[30px] w-[30px] m-3" src={AudioICON} alt="Modes ICON"/>
                    <p className="flex-col">
                        <span className="text-2xl font-bold font-helvetica_neue_heavy">
                            Audio
                        </span>
                        <br/>
                        <span className="font-helvetica_neue_light">Gaming, Movie, Music, Relax</span>
                        <span className="font-helvetica_neue_light">
                            <li>Decoder: supports Dolby Atmos, DTS:X, AURO-3D and mainstream formats</li>
                            <li>DSP: Upmixing, Haptic Feedback, AI Audio Separation and Enhancement, Personalized 3D Audio (based on HRTF),
Head Tracking and Optimization</li>
                            <li>DAC: High resolution and SNR</li>
                            <li>Speakers: 9.2.4ch, 20 separate speakers, each with a class-D amplifier</li>
                            <li>Frequency response 30~20k Hz</li>
                        </span>
                    </p>
                </div>

                <div className="flex flex-row relative pb-8">
                    <img className="h-[30px] w-[30px] m-3" src={FunctionsICON} alt="Functions ICON"/>
                    <p>
                        <span className="text-2xl font-bold font-helvetica_neue_heavy">
                            Others functions
                        </span>
                        <br/>
                        <span className="font-helvetica_neue_light">
                            Internal and external programmable LED strips, Integrated camera / position sensor
                        </span>
                    </p>
                </div>


                <div className="flex flex-row relative pb-8">
                    <img className="h-[30px] w-[30px] m-3" src={MaterialsICON} alt="Functions ICON"/>
                    <p>
                        <span className="text-2xl font-bold font-helvetica_neue_heavy">
                            Materials
                        </span>
                        <br/>
                        <span className="font-helvetica_neue_light">
                            Steel structure, ABS outer case tiles, foam and fabric inner lining, adjustable seat
                        </span>
                    </p>
                </div>


                <div className="flex flex-row relative pb-20">
                    <img className="h-[30px] w-[30px] m-3" src={AccessoriesICON} alt="Functions ICON"/>
                    <p>
                        <span className="text-2xl font-bold font-helvetica_neue_heavy">
                            Accessories
                        </span>
                        <br/>
                        <span className="font-helvetica_neue_light">
                            Rotatable desktop, foldable and VESA compatible monitor stand (optional), 42 inch LG monitor (optional)
Power: 100-240V 50/60Hz internal power supply
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}
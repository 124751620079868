import goto from "../../img/sing-up-newsletter.png";

import React, { useState, useRef } from "react";
import axios from 'axios';

function MyComponent({ goto }) {
    // 创建一个 ref 来存储对输入框的引用
    const emailInputRef = useRef(null);

    // 通过 ref 来获取输入框的值，并发送 POST 请求
    const handleImageClick = () => {
        // 使用 ref 的 current 属性来获取输入元素，并从中获取值
        const email = emailInputRef.current.value;
        handleSignUp(email).catch(console.error);
    };

    const handleSignUp = async (email) => {

        try {
            // 发起POST请求
            const response = await axios.post('https://api.xeo.ink/contacts/', {
                email,
            });

            // 根据状态码处理结果
            if (response.status === 200) {
                alert('Sign up successful');
            }

        } catch (error) {
            // 检查error.response存在，并且取出状态码
            if (error.response) {
                // 服务器响应了请求，但状态码指示有错误
                const {status} = error.response;

                if (status === 400) {
                    alert('Email already registered');
                } else {
                    alert('Server error');
                }
            } else {
                // 请求被发送，但没有响应（服务器没有响应），或者请求设置有误
                alert('Server did not respond or there was an issue with the request');
            }
        }
    };

    // 组件 JSX
    return (
        <div className="flex flex-col xs:flex-row justify-center items-center relative w-full">
            <input
                type="text"
                placeholder="Your email address"
                ref={emailInputRef} // 使用 ref
                className="flex-grow p-4 border-2 border-gray-300 h-14 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
            <img
                className="ml-0 xs:ml-5 mt-2 xs:mt-0 w-14 h-14 flex-shrink-0 cursor-pointer"
                alt="Goto"
                src={goto}
                onClick={handleImageClick} // 添加点击事件处理函数
            />
        </div>
    );
}


export const MobileSignUpNewsletter = () => {
    return (
        <div className="flex flex-col relative bg-[#d9d9d9] pt-20 pb-20 pl-14 pr-14">
            <p className="text-3xl font-helvetica_neue_heavy font-bold text-black mt-5 mb-5">
                Finally, a newsletter you might actually read
            </p>
            <MyComponent goto={goto} />
            <p className="mt-10 font-helvetica_neue_light">
                <span>
                    The XEO Pod is an evolving product, with new features being added to enhance and experience you didn’t think it was possible to improve.
                </span>
                <br/>
                <br/>
                <span>
                    Sign up to our newsletter and you’ll be first to hear about them.
                </span>
            </p>
        </div>
    );
};
import React from "react";

import XEOTheCompantPNG from "../../img/Mobile/About/XEOCompant.png"
import XEOLogoWhitePNG from "../../img/logo-white.png"


export const MobileXEOTheCompant = () => {
    return (
        <div className="flex flex-col">
            <img src={XEOTheCompantPNG} alt="The Compant"/>
            <div className="absolute flex flex-col">
                <p className="text-white ml-5 mr-5">
                    <span className="flex text-xl xs:text-5xl mt-0 xs:mt-20 font-bold font-helvetica_neue_light">
                        An extraordinary product from an extraordinary company
                    </span>
                    <br/>
                    <span className="font-helvetica_neue_light">
                        Pod is the first product from the design team at XEO. What began as an obsession with the quality of sound has developed into a passion for creating environments that affect all the senses and make virtual environments feel real.
                        <br/>
                    </span>
                    <span className="flex mt-0 xs:mt-5 font-helvetica_neue_light">
                        Some may say we are dreamers and maybe that’s true. But it’s only when you tap into human imagination that you get products like the XEO Pod. Products that nobody ever asked for but suddenly need to have. Now that the XEO Pod is here, you have to ask yourself, why didn’t anyone think of this before?
                    </span>
                </p>
                <img className="h-[30px] m-auto ml-5 mt-10" src={XEOLogoWhitePNG} alt="Logo"/>
            </div>
        </div>
    )
}
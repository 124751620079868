import React from "react";

import CameraPNG from "../../img/Mobile/Podchair/Camera.png"


export const MobileCamera = () => {
    return (
        <div className="flex flex-col relative">
            <img src={CameraPNG} alt="Camera" />
            <p className="flex flex-col absolute text-white mt-10 ml-5">
                <span className="text-2xl xs:text-3xl font-bold font-helvetica_neue_heavy">
Introducing a camera sees how you move, then adjusts what you hear
                </span>
                <br/>
                <span className="w-4/5 xs:w-1/2 font-helvetica_neue_light">
Tucked discretely into the roof of the XEO Pod, there’s a tiny, sophisticated optical device that’s angled so it can  notice how you are  moving your head as you watch video or play games. It then instantly sends data to each of the 20 speakers, each of which continually adjusts and tailors the audio to your headspace. When not needed or wanted, the camera can be simply pushed into its pod.
                </span>
            </p>
        </div>
    )
}
import React from "react";
import yulla from "../../img/Mobile/Landing/QuiltyAIGC.png"

export const MobileQuiltyPleasure = () => {
    return (
        <div className="flex flex-col relative h-[600px] bg-clip-border)">
            <img
                className="absolute z-0 inset-0 object-cover w-full h-full"
                alt="Yulllllllla an image"
                src={yulla}
            />
            <div className="relative z-10">
                <p className="absolute text-white text-4xl font-bold top-12 left-5 right-5 font-helvetica_neue_heavy">
                    What’s your guilty <br/>
                    entertainment pleasure?
                </p>
                <div className="absolute text-[18p] text-right right-10 top-[230px] text-[#d9b4ff] font-helvetica_neue_light">
                    <p className="top-1/2">
                        Endurance
                        <br/>
                        gaming
                    </p>
                    <br/>
                    <p className="top-1/2">
                        Bollywood
                        <br/>
                        actionadventures
                    </p>
                </div>
            </div>
        </div>
    );
};
import React from "react";
import PodChairLineDrawingPNG from "../../img/PodChairLineDrawing.png"

export const LineDrawing = () => {
    return (
        <div className="w-full h-[843px]">
            <div className="relative w-full h-[843px] top-0 left-0">
                <div className="relative w-full h-[843px] bg-[100%_100%]">
                    <div className="absolute w-[1020px] h-[842px] top-px left-[10px] overflow-hidden">
                        <img alt="Line Drawing" src={PodChairLineDrawingPNG}/>

                    </div>
                    <p className="absolute w-[508px] top-[402px] left-[841px] font-helvetica_neue_light font-light text-[#ffb800] text-[18px] tracking-[-0.36px] leading-[19.8px]">
                        When you’re watching a movie, you probably sit back in your seat. Whereas, when you’re overthrowing a time
                        lord or firing missiles at the enemy, you tend to sit in a more upright position. The seat is adjustable,
                        with this in mind. And each position will be ultra comfy. Not least because there is a soft and supportive
                        headrest that cushions the neck. This attaches magnetically to the seat back.
                    </p>
                    <p className="absolute w-[518px] top-[280px] left-[841px] [font-family:'Untitled_Sans-Medium',Helvetica] font-medium text-[#ffb800] text-[40px] tracking-[-0.80px] leading-[40px]">
                        You change positions as you react. So can your seat!
                    </p>
                    <div className="absolute w-[99px] top-[20px] left-[50px] font-subject font-[number:var(--subject-font-weight)] text-[#4d1091] text-[length:var(--subject-font-size)] tracking-[var(--subject-letter-spacing)] leading-[var(--subject-line-height)] [font-style:var(--subject-font-style)]">
                        Seat
                    </div>
                </div>
            </div>
        </div>
    );
};
import '../../App.css';
import {Navbar} from "../../Navbar/Navbar";
import {Footer} from "../../Footer/Footer";

import {WeifangHeadquaters} from "./WeifangHeadquaters/WeifangHeadquaters";
import {AboutPod} from "./AboutPod";

function About() {
    return (
        <div className="flex flex-col w-full bg-white">
            <div className="flex flex-col w-full"><Navbar/></div>
            <div className="flex flex-col w-full"><AboutPod/></div>
            <div className="flex flex-col w-full"><WeifangHeadquaters/></div>
            <div className="flex flex-col w-full"><Footer/></div>
        </div>
    );
}

export default About;

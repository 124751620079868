import React from "react";
import NewFrontPNG from "../../img/NewFront.png";
import NewSidePNG from "../../img/NewSide.png";
import SupportedDevicesPNG from "../../img/Specs/SupportedDevices.png";
import ConnectivityPNG from "../../img/Specs/Connectivity.png";
import ModesPNG from "../../img/Specs/Modes.png";
import AudioPNG from "../../img/Specs/Audio.png";
import AudioOpPNG from "../../img/Specs/AudioOperator.png";
import OtherFunctionsPNG from "../../img/Specs/Functions.png";
import MaterialsPNG from "../../img/Specs/Meterials.png";
import AccessoriesPNG from "../../img/Specs/Accessories.png";


export const SpecsBlock = () => {
    return (
        <div className="w-full h-[2284px]">
            <div className="w-full h-[2284px] top-0 left-0">
                <div className="relative w-full h-[2284px] bg-white">
                    <div className="absolute w-[99px] top-[23px] left-[49px] font-helvetica_neue_light font-light text-[#b0b0b0] text-[18px] tracking-[-0.36px] leading-[normal]">
                        Specs
                    </div>
                    <p className="absolute w-[866px] top-[219px] left-[166px] font-helvetica_neue_light font-light text-[#7f00ff] text-[18px] tracking-[-0.36px] leading-[normal]">
                        The specifications of the remarkable XEO Pod will impress, the more you examine them.
                    </p>
                    <div className="absolute w-[103px] top-[556px] left-[134px] font-helvetica_neue_light font-light text-black text-[18px] tracking-[-0.36px] leading-[18px]">
                        144cm
                    </div>
                    <div className="absolute w-[103px] top-[866px] left-[437px] font-helvetica_neue_light font-light text-black text-[18px] tracking-[-0.36px] leading-[18px]">
                        120cm
                    </div>
                    <div className="absolute w-[103px] top-[866px] left-[1015px] font-helvetica_neue_light font-light text-black text-[18px] tracking-[-0.36px] leading-[18px]">
                        102cm
                    </div>
                    <img className="absolute w-[29px] h-[25px] top-[1741px] left-[218px]" alt="Shape" src={SupportedDevicesPNG} />
                    <img className="absolute w-[25px] h-[25px] top-[1237px] left-[219px]" alt="Shape" src={ConnectivityPNG} />
                    <img className="w-[29px] top-[1865px] left-[215px] absolute h-[25px]" alt="Vector" src={ModesPNG} />
                    <img className="absolute w-[32px] h-[32px] top-[997px] left-[216px]" alt="Shape" src={AudioOpPNG} />
                    <img className="absolute w-[32px] h-[21px] top-[1368px] left-[213px]" alt="Shape" src={AudioPNG} />
                    <img className="absolute w-[29px] h-[30px] top-[1633px] left-[217px]" alt="Shape" src={OtherFunctionsPNG} />
                    <img className="absolute w-[22px] h-[25px] top-[1413px] left-[220px]" alt="Shape" src={MaterialsPNG} />
                    <img className="w-[25px] top-[1108px] left-[220px] absolute h-[25px]" alt="Vector" src={AccessoriesPNG} />
                    <p className="absolute w-[762px] top-[177px] left-[163px] [font-family:'Untitled_Sans-Medium',Helvetica] font-medium text-[#7f00ff] text-[40px] tracking-[-0.80px] leading-[40px] whitespace-nowrap">
                        Nerdy? It’s time for the fact fest
                    </p>
                    <div className="flex flex-col items-start gap-[10px] absolute w-[958px] h-[1158px] top-[997px] left-[276px]">
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium">
                Supported devices <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[18px]">
                PC, PS5, XBOX, Apple TV, VR devices with USB4 output <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium text-[40px] tracking-[-0.80px] leading-[40px]">
                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium">
                Connectivity
                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[18px]">
                HDMI, Bluetooth, Apple Airplay2 <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium text-[40px] tracking-[-0.80px] leading-[40px]">
                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
                            <span className="font-medium">Modes</span>
                            <span className="font-medium text-[40px] tracking-[-0.80px] leading-[40px]">
                {" "}
                                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[18px]">
                Gaming, Movie, Music, Relax <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium text-[40px] tracking-[-0.80px] leading-[40px]">
                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
                            <span className="font-medium">Audio</span>
                            <span className="font-medium text-[40px] tracking-[-0.80px] leading-[40px]">
                {" "}
                                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                • Decoder: supports Dolby Atmos, DTS:X, AURO-3D and mainstream formats <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                • DSP: Upmixing, Haptic Feedback, AI Audio Separation and Enhancement, Personalized 3D Audio (based on
                HRTF), <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                Head Tracking and Optimization <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                • DAC: High resolution and SNR <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                • Speakers: 9.2.4ch, 20 separate speakers, each with a class-D amplifier <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                • Frequency response 30~20k Hz <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium text-[40px] tracking-[-0.80px] leading-[40px]">
                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium">
                Others functions <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[18px]">
                Internal and external programmable LED strips, Integrated camera / position sensor <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium text-[40px] tracking-[-0.80px] leading-[40px]">
                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium">
                Materials
                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[18px]">
                Steel structure, ABS outer case tiles, foam and fabric inner lining, adjustable seat <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-medium text-[40px] tracking-[-0.80px] leading-[40px]">
                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
                            <span className="font-medium">Accessories</span>
                            <span className="font-medium text-[40px] tracking-[-0.80px] leading-[40px]">
                {" "}
                                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                Rotatable desktop, foldable and VESA compatible monitor stand (optional), 42 inch LG monitor (optional){" "}
                  <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                Power: 100-240V 50/60Hz internal power supply <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                <br />
              </span>
                        </p>
                        <p className="relative self-stretch [font-family:'Untitled_Sans-Medium',Helvetica] font-normal text-black text-[24px] tracking-[-0.48px] leading-[24px]">
              <span className="font-helvetica_neue_light font-light text-[18px] tracking-[-0.36px] leading-[19.8px]">
                {""}
              </span>
                        </p>
                    </div>
                    <img className="absolute w-[425px] h-[509px] top-[321px] left-[819px]" alt="New side" src={NewSidePNG} />
                    <img className="absolute w-[423px] h-[510px] top-[318px] left-[251px]" alt="New front" src={NewFrontPNG} />
                </div>
            </div>
        </div>
    );
};
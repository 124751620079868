import React from "react";

import PositionPNG from "../../img/Mobile/Podchair/Position.png"


export const MobilePosition = () => {
    return (
        <div className="flex relative bg-[#7f00ff]">
            <img className="flex mt-20 mb-80" src={PositionPNG} alt="Position"/>
            <div className="flex absolute mt-5 xs:mt-20 ml-5 mb-5 bottom-20">
                <p className="flex flex-col text-[#ffb800]">
                    <span className="text-xl xs:text-3xl font-bold font-helvetica_neue_heavy">
                        You change positions as you react. So can your seat!
                    </span>
                    <br/>
                    <span className="mr-6 font-helvetica_neue_light">
When you’re watching a movie, you probably sit back in your seat. Whereas, when you’re overthrowing a time lord or firing missiles at the enemy, you tend to sit in a more upright position. The seat is adjustable, with this in mind. And each position will be ultra comfy. Not least because there is a soft and supportive headrest that cushions the neck. This attaches magnetically to the seat back.

The XEO Pod has a choice of leg design. Either pipe legs with rubber pads - to protect your floor - or soft-glide wheels that allow you to move around.
                    </span>
                </p>
            </div>

        </div>
    )
}
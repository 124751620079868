import React from "react";
import XEOPodSpeaker from "../../img/XEOPod14Speaker.png"

export const TheSound = () => {
    return (
            <div className="relative top-0 left-0 h-[1000px]">
                <div className="absolute z-0">
                    <img alt="XEO POD" src={XEOPodSpeaker}/>
                </div>
                <div className="absolute z-10">
                    <p className="absolute w-[400px] top-[451px] left-[163px] font-h2-sub font-[number:var(--h2-sub-font-weight)] text-white text-3xl tracking-[var(--h2-sub-letter-spacing)] leading-[var(--h2-sub-line-height)] [font-style:var(--h2-sub-font-style)]">
                        The perfect surround sound for anyone with 20 ears
                    </p>
                    <p className="absolute w-[401px] top-[612px] left-[162px] font-helvetica_neue_light font-light text-white text-[18px] tracking-[-0.36px] leading-[19.8px]">
                        World-leading Danish speaker designers, Dynaudio, used the unique sound stage created by the
                        shape of the
                        chair to develop a listening zone unlike any ever experienced before. They strategically placed
                        20
                        high-performance speaker cones beneath the textile surface of the XEO, each indicated by a
                        subtle LED light.
                    </p>
                </div>
            </div>
    );
};
import React from "react";
// Images
import PodChairPNG from "../../img/Mobile/Landing/Podchair.png"
import PodChairBG from "../../img/Mobile/Landing/PodchairBG.png"

export const MobilePodChairTeaser = () => {
    return (
        <div className="flex flex-col relative items-center justify-center h-[630px]">
            <img className="absolute bottom-0 opacity-60 z-10 h-full w-full" src={PodChairBG} alt=""/>
            <div className="flex flex-col bg-black w-full h-full absolute z-0">
                <img className="absolute bottom-3 h-[550px]" src={PodChairPNG} alt=""/>
            </div>

            <div className="absolute z-20 m-auto left-5 right-7">
                <p className="text-white">
                    <span className="text-4xl font-helvetica_neue_heavy font-bold">
                        Warning.
                        <br/>
                        Once you sit inside, you’re surrounded
                    </span>
                    <br/>
                    <span className="font-helvetica_neue_light">
                        It’s a multi-sensory audio experience unlike anything you thought possible
                    </span>
                    <br/>
                    <br/>
                    <span className="font-helvetica_neue_light">
                        Don’t press this link
                    </span>
                </p>
            </div>
        </div>
    )
}
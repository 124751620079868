import React from "react";

import TheSoundPNG from "../../img/Mobile/Podchair/TheSound.png"


export const MobileTheSound = () => {
    return (
        <div className="flex flex-col relative bg-white pt-10 pr-5 pl-5 pb-10">
            <p>
                <span className="flex text-black text-3xl font-helvetica_neue_heavy font-bold mb-2">
                    The perfect surround sound for anyone with 20 ears
                </span>
                <br/>
                <span className="font-helvetica_neue_light">
                    World-leading speaker designers, used the unique sound stage created by the shape of the chair to develop a listening zone unlike any ever experienced before. They strategically placed 20 high-performance speaker cones beneath the textile surface of the POD, each indicated by a subtle LED light.
                </span>
            </p>
            <img className="w-full" src={TheSoundPNG} alt=""></img>
        </div>
    )
}

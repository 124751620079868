import React from "react";

import LightInsidePND from "../../img/Mobile/Podchair/LightInside.png"

export const MobileLightInside = () => {
    return (
        <div className="flex flex-col relative bg-white pt-10 pl-5 pr-5 pb-8 w-full">
            <div className="relative">
                <img src={LightInsidePND} alt="Light Inside" className="mt-10 w-full"/>
                <div className="absolute top-0 left-0 right-0 bottom-0 flex w-3/4">
                    <span className="text-black text-3xl font-helvetica_neue_heavy font-bold">As your mind is blown, the lighting responds</span>
                </div>
            </div>
            <div className="font-helvetica_neue_light">
                <p>
                    XEO features an adaptable, intelligent lighting system that adds another layer to your multimedia experience. You can set it to be simply ambient and relaxing. Or it can adjust to the cinematic settings of a movie you’re watching. Or be in tune with the gaming environment you’re in. With startling effect.
                </p>
            </div>

        </div>
    )
}
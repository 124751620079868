import React from "react";
import podChair from "../../../img/pod-chair.png"

const PodChairTeaser = () => {
    return (
        <div className="flex flex-col bg-black">
            <div className="relative bg-black h-[900px]">
                <img className="h-full object-cover top-0 left-0" alt='POD Chair' src={podChair}/>
                <div className="absolute text-white top-0 mt-60 ml-20 mr-10 w-2/3">
                    <p className="text-7xl font-bold">
                        Warning.
                        <br/>
                        Once you sit inside, you’re surrounded
                    </p>
                    <br/><br/>
                    <p>
                        <span>
                            Surrounded by an experience that will captivate your senses from every angle. Surrounded by a feeling that
                            you are really inside the movie you’re watching. Actually in the the game you’re playing. Surrounded by
                            the escapism you love - but never knew could be this amazing.
                            <br/>
                        </span>
                        <span><br/>
                            This is The XEO Pod. Sit inside and be prepared to feel something you have never felt before:{" "}
                        </span>
                        <span>absolute immersion!</span>
                    </p>
                </div>
            </div>
    </div>);
};


export default PodChairTeaser;

import React from "react";

import XEOWhiteLOGO from "../img/logo-white.png"
import XIcon from "../img/XIcon.png"
import RedditIcon from "../img/RedditIcon.png"
import InsIcon from "../img/InsIcon.png"


export const Footer = () => {
    return (
        <div>
            <div className="bg-[#872deb] text-white border-0 border-[#872deb]">
                <img className="pl-20 pt-20" src={XEOWhiteLOGO} alt=""/>
                {/*<p className="pt-5 pl-20">*/}
                {/*    <span>Goerdyna group</span>*/}
                {/*    <br/>*/}
                {/*    <span>Zhizhen Building, Haidian </span>*/}
                {/*    <br/>*/}
                {/*    <span>Beijing, PRC </span>*/}
                {/*</p>*/}
                <p className="pl-20 pt-5 pb-20">
                <span>
                    Email: hi@xeo.io
                </span>
                {/*    <br/>*/}
                {/*    <span>*/}
                {/*    Email: ken.liupy@goerdyna.com*/}
                {/*</span>*/}
                </p>
            </div>
            <div className="relative h-[120px] bg-[#872deb] text-white border-0 border-[#872deb]">
                <div className="absolute flex flex-row justify-center items-center right-20 bottom-[100px]">
                    {/*<a href="https://www.x.com"><img className="w-11 h-9 mr-5" src={XIcon} alt="X"/></a>*/}
                    {/*<a href="https://www.reddit.com"><img className="h-10 mr-5" src={RedditIcon} alt="Reddit"/></a>*/}
                    <a href="https://www.instagram.com/xeo_lab"><img className="h-10" src={InsIcon} alt="Ins"/></a>
                </div>
                <p className="absolute pt-10 pb-5 right-20">
                    Copyright © 2023 XEO.
                    <br/>
                    All rights reserved.
                </p>
            </div>
        </div>
    )
}

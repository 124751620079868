import React from "react";
import CombineImg from "../../../img/lab2/combine.png"

export const WeifangHeadquaters = () => {
    return (
        <div className="flex flex-col relative">
            <img className="relative w-full object-cover top-0 left-0 z-0" alt="Element pano" src={CombineImg}/>
            <div className="absolute text-white left-[180px] top-[450px] transform -translate-y-1/2 z-10">
                <p className="absolute w-[1020px] top-0 left-0 font-helvetica_neue_heavy font-normal text-transparent text-[60px] tracking-[-1.20px] leading-[60px]">
                            <span className="font-bold text-white">
                              XEO development
                              <br/>
                              centre
                            </span>
                    <span
                        className="[font-family:'Untitled_Sans-Medium',Helvetica] font-medium text-white">&nbsp;</span>
                    <span
                        className="font-helvetica_neue_light font-light text-[#25cea2]">Weifang</span>
                    <span
                        className="font-helvetica_neue_light font-light text-white">&nbsp;</span>
                </p>
            </div>
        </div>
    );
};

import React from "react";
import LightPNG from "../../img/LightChanges.png"

export const LightsBlock = () => {
    return (
        <div className="w-full h-[679px]">
            <div className="w-full h-[679px] top-0 left-0">
                <div className="relative w-full h-[679px] bg-black">
                    {/*<div className="absolute z-0 w-[1206px] h-[679px] top-0 left-0" />*/}
                    <div>
                        <img alt="absolute z-10 Light Change" src={LightPNG} />
                    </div>
                    <p className="absolute w-[437px] top-[329px] left-[841px] font-body-1 font-[number:var(--body-1-font-weight)] text-[#d9d9d9] text-[length:var(--body-1-font-size)] tracking-[var(--body-1-letter-spacing)] leading-[var(--body-1-line-height)] [font-style:var(--body-1-font-style)]">
                        XEO features an adaptable, intelligent lighting system that adds another layer to your multimedia
                        experience. You can set it to be simply ambient and relaxing. Or it can adjust to the cinematic settings of
                        a movie you’re watching. Or be in tune with the gaming environment you’re in. With startling effect.
                    </p>
                    <p className="absolute w-[533px] top-[203px] left-[841px] font-h2-sub font-[number:var(--h2-sub-font-weight)] text-white text-3xl tracking-[var(--h2-sub-letter-spacing)] leading-[var(--h2-sub-line-height)] [font-style:var(--h2-sub-font-style)]">
                        As your mind is blown, <br />
                        the lighting responds
                    </p>
                    <div className="absolute w-[99px] top-[20px] left-[50px] font-subject font-[number:var(--subject-font-weight)] text-[#b0b0b0] text-[length:var(--subject-font-size)] tracking-[var(--subject-letter-spacing)] leading-[var(--subject-line-height)] [font-style:var(--subject-font-style)]">
                        Lights
                    </div>
                </div>
            </div>
        </div>
    );
};
import React from "react";
import PodCamera from "../../img/Camera.png"

export const CameraBlock = () => {
    return (
        <div className="relative w-full h-[676px]">
            <div className="w-full h-[676px] top-0 left-0">
                <div className="h-[676px] overflow-hidden">
                    <div className="relative w-full h-[677px] -top-px left-[-3px] bg-black">
                        <div className="absolute w-[94px] top-[20px] left-[54px] font-subject font-[number:var(--subject-font-weight)] text-[#b0b0b0] text-[length:var(--subject-font-size)] tracking-[var(--subject-letter-spacing)] leading-[var(--subject-line-height)] [font-style:var(--subject-font-style)]">
                            Camera
                        </div>
                        <div>
                            <img alt="Pod Camera" src={PodCamera}/>
                        </div>
                        <p className="absolute w-[460px] top-[346px] left-[54px] font-body-1 font-[number:var(--body-1-font-weight)] text-[#eaeaea] text-[length:var(--body-1-font-size)] tracking-[var(--body-1-letter-spacing)] leading-[var(--body-1-line-height)] [font-style:var(--body-1-font-style)]">
                            Tucked discretely into the roof of the XEO Pod, there’s a tiny, sophisticated optical device that’s angled
                            so it can notice how you are moving your head as you watch video or play games. It then instantly sends
                            data to each of the 20 speakers, each of which continually adjusts and tailors the audio to your
                            headspace. When not needed or wanted, the camera can be simply pushed into its pod.
                        </p>
                        <p className="absolute w-[485px] top-[186px] left-[54px] font-h2-sub font-[number:var(--h2-sub-font-weight)] text-[#eaeaea] text-3xl tracking-[var(--h2-sub-letter-spacing)] leading-[var(--h2-sub-line-height)] [font-style:var(--h2-sub-font-style)]">
                            Introducing a camera sees how you move, then adjusts what you hear
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
import React from "react";

// Images
import PODViewTV from "../../img/Mobile/Podchair/PODViewTV2.png"
// import PODRight from "../../img/Mobile/Podchair/Frame 3.png"

export const MobileLandingExperience = () => {
    return (
        <div className=" flex flex-col bg-[#2E2E2E] justify-center items-center">
            <img className="bg-[#d9d9d9] w-full" src={PODViewTV} alt=""></img>
            <div className="absolute z-10 flex flex-col text-[#f4f6f8] left-12 mb-10">
                <p className="font-helvetica_neue_heavy text-4xl font-bold">
                    In this seat.
                    <br/>
                    Out of this world
                </p>
                <p className="font-helvetica_neue_light mt-5">
                    Immersive audio experience pod
                </p>
            </div>
        </div>
    )
}
import React from "react";

import SoftInsidePNG from "../../img/Mobile/Podchair/SoftInside.png"


export const MobileSoftInside = () => {
    return (
        <div className="flex flex-col mt-10">
            <p className="flex flex-col ml-5 mr-5">
                <span className="text-3xl font-bold font-helvetica_neue_heavy">
                    Hard on the outside.
                    <br/>
Soft and friendly on the inside
                </span>
                <br/>
                <span className="font-helvetica_neue_light">
XEO’s pentagonal, architectural silhouette is unashamedly eye-catching. Odd, even. Its sharp lines make a statement. But, when you sit inside, it feels like being in an upholstered egg: beautifully comfortable, whilst also being supportive. This attention to detail
is especially important because you will naturally want to be in your XEO for long periods of time.
                </span>
            </p>
            <img src={SoftInsidePNG} alt="Soft Inside"/>
        </div>)
}
import React from "react";
import {MobilePodChairTeaser} from "./PodChairTeaser";
import {MobileXEOCompanyTeaser} from "./XEOCompany";
import {MobileQuiltyPleasure} from "./QuiltyPleasure"
import {MobileSignUpNewsletter} from "./SignUpNewsletter";
import {Navbar} from "../../Navbar/Navbar";
import {MobileFooter} from "../Footer";


function MobileLanding() {
    return (
        // Mobile Landing Page
        <div className="flex flex-col w-full">
            <div><Navbar/></div>
            <div><MobilePodChairTeaser/></div>
            <div><MobileXEOCompanyTeaser/></div>
            <div><MobileQuiltyPleasure/></div>
            <div><MobileSignUpNewsletter/></div>
            <div><MobileFooter/></div>
       </div>
    )

}

export default MobileLanding;

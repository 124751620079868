// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
//
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Landing from './App';
import PodChair from './Desktop/PodChair/PodChair';
import About from './Desktop/About/About'; // 引入About组件
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import MobileLanding from "./Mobile/Landing/LandingPage";
import MobilePodchair from "./Mobile/Podchair/PodchairPage";
import MobileAbout from "./Mobile/About/AboutPage";

import { useEffect, useState } from 'react';


const useMobileDetect = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        const mobile = Boolean(userAgent.match(
            /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
        ));
        setIsMobile(mobile);
    }, []);

    return isMobile;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
    const isMobile = useMobileDetect();

    useEffect(() => {
        document.title = 'XEO';
    }, []);

    return (
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={isMobile ? <MobilePodchair/> : <PodChair />} />
                    <Route path="/pod" element={isMobile ? <MobileLanding/> : <Landing />} />
                    {/*<Route path="/pod" element={isMobile ? <MobilePodchair/> : <PodChair />} />*/}
                    <Route path="/about" element={isMobile ? <MobileAbout/> : <About />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
};

root.render(<App />);
import React from "react";
import DolbyAtmos from "../../img/DolbyAtmos.png"

export const TheExperience = () => {
    return (
        <div className="relative w-full h-[754px]">
            <div className="w-full bg-bg-the-experience-color h-[754px] top-0 left-0">
                <div className="relative w-full h-[754px]">
                    <div className="absolute w-[150px] font-subject font-[number:var(--subject-font-weight)] text-[#0000004c] text-[length:var(--subject-font-size)] tracking-[var(--subject-letter-spacing)] leading-[var(--subject-line-height)] [font-style:var(--subject-font-style)]">
                        The Experience
                    </div>
                    <p className="absolute w-[1204px] top-[185px] left-[117px] font-helvetica_neue_heavy font-bold text-[#f4f6f8] text-[60px] text-center tracking-[-1.20px] leading-[60px]">
                        You are no longer just an observer
                    </p>
                    <p className="absolute w-[1128px] top-[296px] left-[154px] font-helvetica_neue_light font-light text-[#f4f6f8] text-[36px] text-center tracking-[-0.72px] leading-[normal]">
                        XEO takes you further. You are no longer just watching and listening. You are sitting within a
                        purpose-designed pod that insulates you from reality. It’s a multi-sensory experience unlike anything you
                        thought possible.
                    </p>
                    <img className="absolute w-[464px] h-[66px] top-[582px] left-[487px]" alt="Vector" src={DolbyAtmos} />
                </div>
            </div>
        </div>
    );
};
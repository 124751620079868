import React from "react";
import xeoLogo from "../../img/logo-black.png"

export const MobileXEOCompanyTeaser = () => {
    return (
        <div className="flex flex-col relative h-[650px] justify-center items-center">
            <div className="absolute font-helvetica_neue_light">
                <img className="relative" alt="Xeo logo" src={xeoLogo}/>
                <br/>
                <p className="absolute text-black left-4">
                <span className="leading-[21.6px]">
                  We are all about High-end audio, <br/>
                  immersive experiences and design. <br/>
                  <br/>
                  XEO is a new audio brand conceived <br/>
                  inside Dynaudio, the pioneering <br/>
                  Danish loudspeaker company
                  <br/>
                </span>
                    <span className="leading-[24px]">
                  <br/>
                </span>
                    <span className="text-[#7f00ff] leading-[24px] underline">Wanna know more?</span>
                </p>
            </div>
        </div>
    );
};
import React from "react";
import AccessoriesPNG from "../../img/Mobile/Podchair/Accessories.png"


export const MobileAccessories = () => {
    return (
        <div className="flex flex-col mt-10">
            <img src={AccessoriesPNG} alt="Accessories"/>
            <p>
                <span className="flex text-3xl font-bold font-helvetica_neue_heavy m-auto p-5 pb-0">
                    We call them optional extras, but you’ll think they’re must-haves
                </span>
                <br/>
                <span className="flex p-5 pb-20 font-helvetica_neue_light">
                    How could we make your screen part of the design aesthetic? Was there a way to make accessories like a side table and cup holder XEO modules? The answer is yes. We designed an elegant XEO TV swivel arm, which hides all the horrible wiring. And an equally smart snack table, with integral wireless charger, and moveable cup holder.
                </span>
            </p>

        </div>
    )
}